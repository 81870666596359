import React, {useState}  from 'react'
import { Row, Col, Faq } from 'react-bootstrap'
import { container } from '../styles/style.scss'

const Bio = ({ title, content }) => {

    const [isActive, setIsActive] = useState(false)

    return (
        <div className={container} id="faq">
                <div className="accordion-item">
                <div 
                    className="accordion-title"
                    onClick={() => setIsActive(!isActive)}
                >
                    <div>{title}</div>
                    <div>{isActive ? '-' : '+'}</div>
                </div>
                {isActive &&<div className="accordion-content">{content}Hello</div>}
                </div>
            </div>
    )

}

export default Bio