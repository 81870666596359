import React, {useState}  from 'react'
import { Row, Col, Button, Card, P} from 'react-bootstrap'
import { container } from '../styles/style.scss'
import PaymentModal from "../components/paymentModal";
import signatureGIF from '../images/03_matt_signature_v03.gif'
import flickerGIF from '../images/01_light_flicker.gif'
import axios from "redaxios";
import CustomNavbar from '../components/navbar'
import Bio from "../components/bio"
import { bioData } from "../utils/bioContent"
import Header from '../components/header'

const captchaKey = process.env.SITE_KEY_DEV

const Explorer =() => {

    const [rate, setRate] = useState("")
    const [address, setAddress] = useState("")
    const [show, setShow] = useState(false);
    const [verified, isVerified] = useState(false)
    const [loader, setLoader] = useState(false)

    // How can we change the background of the disabled button and get the laoder to work
    const handleCaptchaClick = (value) => {
        console.log("entering submit")
        value.preventDefault()
        setLoader(true)
        console.log("value is ")
    }

    
    // crossdomain line might not be needed
    // { crossdomain: true}
    const getRates = async () => {
        // const dataEnd = axios.get("http://localhost:80/rates").then(res => {

        const dataEnd = axios.get("https://infinite-taiga-27008.herokuapp.com/rates").then(res => {
            console.log("The response " + JSON.stringify(res))
            console.log("The data is " + dataEnd)
            setRate(res.data)
            // setAuthor(res.data.author)
        }).catch((error)=> {
            if(error.response) {
                // When response status code is out of 2xx range
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)
            } else if (error.request) {
                // When no response was received after request was made
                console.log(error.request)
            } else {
                // Error Message
                console.log(error.message)
            }
        })
    }

    const getAddress = async () => {
        // const data = axios.get("http://localhost:80/address").then(res => {

        const data = axios.get("https://infinite-taiga-27008.herokuapp.com/address").then(res => {
            console.log("Address response " + JSON.stringify(res))
            console.log("The data is " + data)
            setAddress(res.data)
        })
    }

    const handleCaptcha= (value) => {
        console.log("wen verified?")
        console.log('verified value:', value)
        setLoader(false)
        isVerified(true)
        console.log("things are looking good")
    }

    const openPaymentWindow = () => {
        const paymentUrl = "https://payment.nft-maker.io/?p=e356acc1b62047e4833423f8335fcb40&c=1";

        // Specify the popup width and height
        const popupWidth = 500;
        const popupHeight = 700;

        // Calculate the center of the screen
        const left = window.top.outerWidth / 2 + window.top.screenX - ( popupWidth / 2);
        const top = window.top.outerHeight / 2 + window.top.screenY - ( popupHeight / 2);

        const popup =  window.open(paymentUrl, "NFT-MAKER PRO Payment Gateway",  `popup=1, location=1, width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`);

        document.body.style = "background: rgba(0, 0, 0, 0.5)";

        // Continuously check whether the popup has been closed
        const backgroundCheck = setInterval(function () {
            if(popup.closed) {
                clearInterval(backgroundCheck);

        console.log("Popup closed");

        // Remove dim background
        document.body.style = "";
        }
    },1000)
    }

    return (
        <div>
            <CustomNavbar/>
            <Header/>
            
            <Card className="mx-auto" style={{ width: '22rem', margin: '3rem' }}>
            <Card.Body >
                <Card.Title
                    style={{
                        paddingTop: `2.0875rem`,   
                        textAlign: "center",   
                        fontFamily: 'Bungee', 
                        fontSize: '1.5rem',
                        color: `#167BFF`
                        }}>Matt Fredericksen
                </Card.Title>
                <Card.Text >
                    <Row>
                       
                    <a href="https://open.spotify.com/artist/374ThTKEBJGy2RpIczlaaC?si=bxVDIIrzQXu5ytFPcQpesg"
                        className="card-links" target="_blank"
                    >
                        Spotify
                    </a>
                   
                    <a href="https://www.facebook.com/mattfredericksenmusic"
                        className="card-links" target="_blank"
                    >
                        Facebook
                    </a>
                    <a href="https://vm.tiktok.com/TTPdY3hNnj/"
                        className="card-links" target="_blank"
                    >
                        TikTok
                    </a>
                       
                    </Row>
                </Card.Text>
         
                <Row
                
                >
                    <Col style={{padding: '.5rem'}}>
                        <Card.Img src={signatureGIF} />
                        <p style={{
                            textAlign: "center",   
                            fontFamily: 'Montserrat',
                        }}>25% of supply</p>
                    </Col>
                    <Col style={{padding: '.5rem'}}>
                        <Card.Img  src={flickerGIF} />
                        <p style={{
                            textAlign: "center",   
                            fontFamily: 'Montserrat',
                        }}>75% of supply</p>
                    </Col>
              
                </Row>
                <div className="accordion">
                        {bioData.map(({ title, content }) => (
                        <Bio title={title} content={content} />
                        ))}
                </div>
                <Row>
                    <Button className="mx-auto" onClick={() => {
                    openPaymentWindow()
                }}  variant="primary" 
                             style={{              
                                width: `17.0875rem`,
                                height: `2.5875rem`,
                                textAlign: "center",
                                fontFamily: 'Montserrat',
                                color: 'white',
                                borderRadius: `1rem`,
                                // border: '3px solid orange',
                                background: "#48CF89"
                                // background : loader ? "#48CF89" : "#ccc",
                            }}
                        >Purchase Vacancy NFT   
                    </Button>
                </Row>
            </Card.Body>
            </Card>
     
            {/* <PaymentModal
                show={show}
                rate={rate}
                address={address}
                setShow={(b) => setShow(b)}
            /> */}
        
        </div>
    )
}

export default Explorer
